<template>
  <svg 
    viewBox="0 0 432.3 116.91"
    xmlns="http://www.w3.org/2000/svg" 
    title="Herd"
    aria-label="Herd Logo" 
    aria-hidden="true"
  >
    <path 
      d="M432.3 55.96c0 19.5-5.49 34.52-16.46 45.12-10.97 10.55-26.38 15.83-46.26 15.83h-38.62V25.15h28.61v65.88h8.57c11.69 0 20.26-2.74 25.7-8.23 5.4-5.49 8.15-13.89 8.15-25.2 0-10.59-2.57-18.4-7.72-23.51-4.05-4.01-9.75-6.42-17.05-7.3v-1.65h47.94c4.77 8.31 7.13 18.61 7.13 30.81ZM330.97 18.23V0h41.32c19.16 0 33.93 4.81 44.36 14.39 1.35 1.22 2.57 2.49 3.71 3.84h-89.39Zm-79.93 62.42v36.25h-30.18V91.41c3.25-3.17 5.74-6.2 7.89-8.82 1.9-2.32 3.5-4.26 4.98-5.49 7.47-6.33 15.57-6.2 23-6.08 5.23.08 10.17.17 14.86-1.69 4.73-1.86 8.74-4.77 12.11-8.23 3.38-3.42 6.12-7.3 8.36-11.27 3.38-5.91 4.98-9.92 6.25-13 1.86-4.6 2.74-6.88 9.54-11.94.8 2.7 1.31 5.49 1.43 8.36 1.22 22.16-2.28 33.51-19.75 43.77l27.05 39.88h-34.19l-23.68-36.25h-7.68Zm-30.18 1.52V0h49.13c17.43 0 29.54 7.85 35.37 18.91-9.37 6.71-10.47 9.5-12.91 15.62-1.18 2.87-2.66 6.58-5.87 12.2-2.03 3.55-4.47 7.01-7.34 9.96-2.83 2.87-6.12 5.28-9.96 6.79-3.55 1.39-7.89 1.31-12.45 1.27-8.57-.13-17.85-.3-27.14 7.55-1.94 1.6-3.71 3.76-5.82 6.29-.89 1.14-1.9 2.32-3 3.59Zm29.8-27.05h10.3c12.07 0 18.15-5.19 18.15-14.94 0-8.02-5.95-13.29-17.81-13.29h-10.64v28.23Zm-52.16 61.79h-77.25V52.84L170.08 0h28.4v25.36h-47.56v20.85h37.18v25.24h-37.18V91.2h47.56v25.7Zm-77.25-73.99V0h38.45l-38.45 42.92ZM99.18 116.9H67.74V73.81h-36.3v43.09H0V74.95c.55-.76 1.1-1.52 1.6-2.19 2.53-3.63 4.18-5.91 7.05-6.92 2.66-.97 4.98-1.56 7.05-1.73 1.94-.21 3.71-.04 5.49.51 1.43.42 2.83.84 4.05 1.22 2.79.89 4.6 1.43 6.71 1.73 3 .42 5.49.38 7.64 0h.04c2.28-.38 4.22-1.18 5.95-2.32 4.26-2.79 7.26-6.2 9.54-10.13 1.31-2.28 2.41-4.68 3.33-7.17h9.28V0h31.44v116.91ZM0 64.4V0h31.44v47.94h20.22c-.63 1.39-1.27 2.7-2.03 3.97-1.81 3.17-4.18 5.91-7.51 8.06-1.01.63-2.15 1.14-3.59 1.39-1.56.25-3.46.25-5.74-.04-1.56-.21-3.17-.72-5.7-1.52-1.1-.34-2.36-.72-4.14-1.27-2.53-.72-5.06-.97-7.85-.72-2.62.25-5.4.93-8.57 2.07-2.87 1.06-4.77 2.49-6.54 4.52H0Z"
      fill="currentColor" 
    />
  </svg>
</template>